import { Button, Card, CardFooter, Text } from "@fluentui/react-components";
import {
  bundleIcon,
  CheckmarkSquareFilled,
  CheckmarkSquareRegular,
  DismissSquareFilled,
  DismissSquareRegular
} from "@fluentui/react-icons";

import { DataRow } from "../types/data";

const CheckmarkSquare = bundleIcon(CheckmarkSquareFilled, CheckmarkSquareRegular);
const DismissSquare = bundleIcon(DismissSquareFilled, DismissSquareRegular);

type CommentCardProps = {
  row: DataRow,
  onApprove?: Function,
  onDeny?: Function,
}

const CommentCard = (props: CommentCardProps) => {
  const { row, onApprove, onDeny } = props;

  return (
    <Card style={{ maxWidth: "500px" }}>
      <Text>{row.text}</Text>
      <CardFooter>
        {onApprove && <Button icon={<CheckmarkSquare />} onClick={(evt) => onApprove?.(evt, row, true)}>Approve</Button>}
        {onDeny && <Button icon={<DismissSquare />} onClick={(evt) => onDeny?.(evt, row, false)}>Deny</Button>}
      </CardFooter>
    </Card>
  );
}

export default CommentCard;
