import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import useSWRImmutable from "swr/immutable";

import {
  TabList,
  Tab,
  makeStyles,
  shorthands,
  SelectTabEvent,
  SelectTabData,
  Spinner,
  TabValue,
  Input,
  useId,
} from "@fluentui/react-components";
import { Alert } from '@fluentui/react-components/unstable';
import { Search20Regular } from "@fluentui/react-icons";

import {
  // jsonFetch,
  transformData,
  sortData,
} from "../utils/data";
import { DataCollection, DataRow } from "../types/data";

import CommentCard from "./CommentCard";
import Playground from "./Playground";

import hfData from "../train.json";

// const EXAMPLE_DATASET_ENDPOINT = "https://datasets-server.huggingface.co/rows?dataset=mteb%2Ftoxic_conversations_50k&config=mteb--toxic_conversations_50k&split=train&offset=0&limit=100";

const TAB_VALUE_INBOX = "inbox";
const TAB_VALUE_DENIED = "denied";
const TAB_VALUE_APPROVED = "approved";
const TAB_VALUE_SEARCH = "search";
const TAB_VALUE_PLAYGROUND = "playground";

const useStyles = makeStyles({
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    ...shorthands.padding("50px", "20px"),
    rowGap: "20px",
  },
  panels: {
    ...shorthands.padding(0, "10px"),
    "& th": {
      textAlign: "left",
      ...shorthands.padding(0, "30px", 0, 0),
    },
  },
});

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const styles = useStyles();

  // Set the current active tab based on the router path.
  const [selectedValue, setSelectedValue] = React.useState<TabValue>(
    location.pathname === "/" ? TAB_VALUE_INBOX : location.pathname.split("/")[1]
  );

  // Get data from Hugging Face Datasets Server and cache it to use as an
  // example integration.
  // const { data: hfData, error, isLoading } = useSWRImmutable(EXAMPLE_DATASET_ENDPOINT, jsonFetch);
  let error = null; // Temporary since we aren't remotely fetching data;
  const [isLoading, setIsLoading] = React.useState<Boolean>(true)
  const [data, setData] = React.useState<Record<DataCollection, Array<DataRow>>>()

  React.useEffect(() => {
    const loadData = async () => {
      setTimeout(() => {
        setData(sortData(transformData(hfData)));
        setIsLoading(false);
      }, 1500);
    };

    loadData();
  }, []);

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
    navigate((data.value === TAB_VALUE_INBOX ? "/" : data.value) as string);
  };

  // https://huggingface.co/datasets/mteb/toxic_conversations_50k/viewer/mteb--toxic_conversations_50k/train?row=8

  const onCardDismiss = (event: React.MouseEvent<HTMLElement>, target: DataRow, approved: boolean) => {
    if (!data) return;

    const filterFn = (row: DataRow) => row.uuid !== target.uuid;

    let map: Record<DataCollection, Array<DataRow>> = {
      inbox: data.inbox.filter(filterFn),
      approved: [...data.approved],
      denied: [...data.denied],
    };

    // Naively filter and add to collections - this can be improved during the
    // actual integration and won't matter too much with ~100 rows.
    if (approved) {
      map.approved.unshift(target);
      map.denied = data.denied.filter(filterFn);
    } else {
      map.approved = data.approved.filter(filterFn);
      map.denied.unshift(target);
    }

    setData(map);
  };

  const Inbox = React.memo(() => (
    <div role="tabpanel" aria-labelledby="Inbox" style={{ display: "grid", gap: "1rem", justifyContent: "start" }}>
      {data?.inbox.map((row, idx) => (
        <CommentCard key={idx} row={row} onApprove={onCardDismiss} onDeny={onCardDismiss} />
      ))}
    </div>
  ));

  const Denied = React.memo(() => (
    <div role="tabpanel" aria-labelledby="Denied" style={{ display: "grid", gap: "1rem", justifyContent: "start" }}>
      {data?.denied.map((row, idx) => (
        <CommentCard key={idx} row={row} onApprove={onCardDismiss} />
      ))}
    </div>
  ));

  const Approved = React.memo(() => (
    <div role="tabpanel" aria-labelledby="Approved" style={{ display: "grid", gap: "1rem", justifyContent: "start" }}>
      {data?.approved.map((row, idx) => (
        <CommentCard key={idx} row={row} onDeny={onCardDismiss} />
      ))}
    </div>
  ));

  const Search = React.memo(() => {
    const searchInputId = useId("search-input");
    const onChangeHandler = () => console.log('changed..');

    return (
      <div role="tabpanel" aria-labelledby="Search" style={{ display: "flex" }}>
        <Input
          contentAfter={<Search20Regular />}
          id={searchInputId}
          placeholder="Search for comments..."
          appearance="underline"
          size="large"
          style={{ width: "500px", paddingLeft: 0 }}
          autoFocus={true}
          onChange={onChangeHandler}
        />
      </div>
    )
  });

  // Maps the selected value from the tabs list to one of the tab components
  // above. Use <CurrentTab /> to render.
  const tabs: Record<string, React.ElementType> = {
    [TAB_VALUE_INBOX]: Inbox,
    [TAB_VALUE_DENIED]: Denied,
    [TAB_VALUE_APPROVED]: Approved,
    [TAB_VALUE_SEARCH]: Search,
    [TAB_VALUE_PLAYGROUND]: Playground,
  }
  const CurrentTab = tabs[selectedValue as string];

  return (
    <div className={styles.root}>
      <TabList selectedValue={selectedValue} onTabSelect={onTabSelect} size="large">
        <Tab value={TAB_VALUE_INBOX}>Inbox</Tab>
        <Tab value={TAB_VALUE_DENIED}>Denied</Tab>
        <Tab value={TAB_VALUE_APPROVED}>Approved</Tab>
        <Tab value={TAB_VALUE_SEARCH}>Search</Tab>
        <Tab value={TAB_VALUE_PLAYGROUND}>Playground</Tab>
      </TabList>
      <div className={styles.panels}>
        {(error &&
          <Alert intent="error">An unexpected error has occurred while loading data set.</Alert>
        ) ||
          (isLoading && <Spinner size="small" />) || <CurrentTab />
        }
      </div>
    </div>
  );
};

export default App;
