import { HFRowsResponse, HFTransformedRow } from "../types/hf";
import { DataCollection, DataRow } from "../types/data";

export const jsonFetch = (url: string) => fetch(url).then((res) => res.json());

export const transformData = (data: HFRowsResponse) => {
  if (data?.rows) {
    return data.rows.map((row) => ({ text: row.row.text, approved: !!row.row.label }));
  }

  return [];
};

export const sortData = (data: Array<HFTransformedRow>) => {
  const map: Record<DataCollection, Array<DataRow>> = {
    inbox: [],
    approved: [],
    denied: [],
  };

  data = shuffleArray(data);

  // Take the first 8 rows (arbitrary) and add to inbox
  map.inbox = data.slice(0, 8).map(({ text }) => ({ text, uuid: uuidv4() }));

  // Take the rest and sort into approved or denied
  return data.slice(8).reduce((acc, row) => {
    const { text, approved } = row;

    acc[approved ? "approved" : "denied"].push({ text, uuid: uuidv4() });

    return acc;
  }, map);
}

// The Fisher-Yates shuffling algorithm.
// @from: <https://dev.to/codebubb/how-to-shuffle-an-array-in-javascript-2ikj>.
const shuffleArray = (array: Array<HFTransformedRow>) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

// Generate a random UUID.
// @from: <https://stackoverflow.com/a/2117523>.
function uuidv4() {
  return ([1e7] as any + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}
