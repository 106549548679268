import * as React from "react";

import { Button, Textarea } from "@fluentui/react-components";

const Playground = () => {
  const textarea = {
    style: { width: "500px", minHeight: "150px" },
  };

  // @TODO: make <Textarea /> below controlled to set <Button /> disabled
  // state and to submit prompt in the future.

  const [score, setScore] = React.useState<Number>();

  const onScoreHandler = () => {
    const randomScore = Math.floor(Math.random() * 100) + 1;

    setScore(randomScore);
  };

  return (
    <div role="tabpanel" aria-labelledby="Playground" style={{ display: "grid", gap: "1rem", justifyContent: "start" }}>
      <div>
        <Textarea
          size="large"
          textarea={textarea}
          placeholder="Enter a comment to give it a score..."
          autoFocus
        />
      </div>
      <div style={{ width: "500px", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }}>
        <Button onClick={() => onScoreHandler()}>Score Text</Button>

        {score && (
          <span>
            <strong>Score:</strong> <span>{String(score)}</span> ({score > 50 ? "Denied" : "Approved"})
          </span>
        )}
      </div>
    </div>
  )
}

export default Playground;
